import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="24">
            <defs>
                <linearGradient
                    id="a"
                    x1=".183%"
                    x2="100.273%"
                    y1="49.96%"
                    y2="49.96%"
                >
                    <stop offset="0%" stopColor="#007940"></stop>
                    <stop offset="22.85%" stopColor="#00873F"></stop>
                    <stop offset="74.33%" stopColor="#40A737"></stop>
                    <stop offset="100%" stopColor="#5CB531"></stop>
                </linearGradient>
                <linearGradient
                    id="b"
                    x1="-57.527%"
                    x2="232.391%"
                    y1="50.124%"
                    y2="50.124%"
                >
                    <stop offset="0%" stopColor="#007940"></stop>
                    <stop offset="22.85%" stopColor="#00873F"></stop>
                    <stop offset="74.33%" stopColor="#40A737"></stop>
                    <stop offset="100%" stopColor="#5CB531"></stop>
                </linearGradient>
                <linearGradient
                    id="c"
                    x1="-62.802%"
                    x2="253.671%"
                    y1="49.858%"
                    y2="49.858%"
                >
                    <stop offset="0%" stopColor="#007940"></stop>
                    <stop offset="22.85%" stopColor="#00873F"></stop>
                    <stop offset="74.33%" stopColor="#40A737"></stop>
                    <stop offset="100%" stopColor="#5CB531"></stop>
                </linearGradient>
                <linearGradient
                    id="d"
                    x1=".176%"
                    x2="101.808%"
                    y1="50.006%"
                    y2="50.006%"
                >
                    <stop offset="0%" stopColor="#1F286F"></stop>
                    <stop offset="47.51%" stopColor="#004E94"></stop>
                    <stop offset="82.61%" stopColor="#0066B1"></stop>
                    <stop offset="100%" stopColor="#006FBC"></stop>
                </linearGradient>
                <linearGradient
                    id="e"
                    x1="-.576%"
                    x2="98.133%"
                    y1="49.914%"
                    y2="49.914%"
                >
                    <stop offset="0%" stopColor="#6C2C2F"></stop>
                    <stop offset="17.35%" stopColor="#882730"></stop>
                    <stop offset="57.31%" stopColor="#BE1833"></stop>
                    <stop offset="85.85%" stopColor="#DC0436"></stop>
                    <stop offset="100%" stopColor="#E60039"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="url(#a)"
                    d="M26.154 0a4 4 0 00-3.996 4.004V8.16h5.643c.131 0 .285 0 .395.022 1.273.066 2.217.726 2.217 1.87 0 .902-.637 1.672-1.822 1.826v.044c1.295.088 2.283.814 2.283 1.936 0 1.21-1.098 2.002-2.547 2.002h-6.19V24h5.861a4 4 0 003.996-4.004V0h-5.84z"
                    transform="translate(5)"
                ></path>
                <path
                    fill="url(#b)"
                    d="M24.112 14.592h2.305c.066 0 .22-.022.286-.022.439-.088.812-.484.812-1.034 0-.528-.373-.924-.812-1.034-.066-.022-.198-.022-.286-.022h-2.305v2.112z"
                    transform="translate(5)"
                ></path>
                <path
                    fill="url(#c)"
                    d="M27.23 10.318a.935.935 0 00-.813-.946c-.044 0-.153-.022-.22-.022h-2.085v1.936h2.086c.066 0 .197 0 .22-.022a.935.935 0 00.812-.946z"
                    transform="translate(5)"
                ></path>
                <path
                    fill="url(#d)"
                    d="M4.018 0A4.002 4.002 0 00.022 4.007v9.887c1.12.55 2.283.902 3.447.902 1.383 0 2.13-.836 2.13-1.981V8.147h3.424v4.646c0 1.805-1.12 3.28-4.918 3.28C1.8 16.073 0 15.567 0 15.567V24h5.862a4.002 4.002 0 003.996-4.007V0h-5.84z"
                    transform="translate(5)"
                ></path>
                <path
                    fill="url(#e)"
                    d="M15.064 0a3.998 3.998 0 00-3.996 4v5.23c1.01-.856 2.766-1.406 5.599-1.274 1.514.066 3.139.484 3.139.484v1.692c-.812-.418-1.778-.791-3.03-.88-2.151-.153-3.447.902-3.447 2.748 0 1.868 1.296 2.923 3.447 2.747 1.252-.088 2.218-.483 3.03-.879v1.692s-1.603.418-3.14.484c-2.832.132-4.588-.418-5.598-1.275V24h5.862a3.998 3.998 0 003.996-4V0h-5.862z"
                    transform="translate(5)"
                ></path>
            </g>
        </svg>
    )
}

export default Icon
