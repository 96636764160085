import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="24">
            <defs>
                <radialGradient
                    id="a"
                    fx="50%"
                    fy="50%"
                    gradientTransform="matrix(0 1 -.99965 0 1 0)"
                >
                    <stop offset="0%" stopColor="#FED"></stop>
                    <stop offset="100%" stopColor="#E77A2C"></stop>
                </radialGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#0B1015"
                    d="M3.446 13.676c-.413.365-.944.523-1.789.523h-.35V9.852h.35c.845 0 1.356.148 1.789.532.452.394.72 1.005.72 1.635 0 .632-.268 1.264-.72 1.657zM1.919 8.739H0v6.573h1.91c1.012 0 1.745-.236 2.388-.758a3.282 3.282 0 001.215-2.525c0-1.941-1.477-3.29-3.594-3.29zm4.197 6.573H7.42V8.739H6.116zm4.498-4.052c-.784-.285-1.015-.473-1.015-.827 0-.414.41-.729.974-.729.391 0 .713.158 1.055.531l.682-.876a2.957 2.957 0 00-1.969-.73c-1.183 0-2.087.809-2.087 1.882 0 .908.421 1.37 1.646 1.805.513.177.773.294.904.375.26.167.392.403.392.679 0 .533-.432.927-1.015.927-.623 0-1.124-.306-1.426-.877l-.842.8c.601.865 1.324 1.25 2.32 1.25 1.354 0 2.307-.887 2.307-2.158 0-1.045-.44-1.519-1.926-2.052m2.338.769c0 1.934 1.546 3.431 3.535 3.431.562 0 1.044-.109 1.637-.384v-1.509c-.523.514-.985.72-1.578.72-1.315 0-2.249-.937-2.249-2.268 0-1.26.964-2.256 2.19-2.256.621 0 1.094.216 1.637.739v-1.51c-.573-.284-1.045-.401-1.607-.401-1.979 0-3.565 1.528-3.565 3.438"
                ></path>
                <ellipse
                    cx="22.185"
                    cy="12.04"
                    fill="url(#a)"
                    rx="3.565"
                    ry="3.46"
                ></ellipse>
                <path
                    fill="#0B1015"
                    d="M28.704 13.154L26.917 8.74h-1.426l2.843 6.74h.702l2.892-6.74h-1.416l-1.808 4.415m3.817 2.157h3.704v-1.113h-2.399v-1.775h2.307V11.31h-2.307V9.85h2.4V8.74H32.52v6.573m6.254-3.549h-.38V9.772h.4c.816 0 1.257.337 1.257.976 0 .66-.442 1.016-1.277 1.016zm2.62-1.085c0-1.232-.862-1.94-2.369-1.94h-1.938v6.573h1.307V12.67h.17l1.807 2.642h1.606l-2.11-2.769c.986-.198 1.527-.858 1.527-1.864z"
                ></path>
            </g>
        </svg>
    )
}

export default Icon
