import React from 'react'
import PropTypes from 'prop-types'
import Visa from './Images/Visa'
import MasterCard from './Images/MasterCard'
import Jcb from './Images/Jcb'
import Discover from './Images/Discover'
import Amex from './Images/Amex'
import Cc from './Images/Cc'
import Diners from './Images/Diners'

import styles from './CardBrandImage.module.scss'

function CardBrandImage({ brand = '', style, ...props }) {
    let BrandImage

    switch (brand.toLowerCase()) {
        case 'visa':
            BrandImage = <Visa />
            break
        case 'mastercard':
            BrandImage = <MasterCard />
            break
        case 'amex':
            BrandImage = <Amex />
            break
        case 'discover':
            BrandImage = <Discover />
            break
        case 'diners':
            BrandImage = <Diners />
            break
        case 'jcb':
            BrandImage = <Jcb />
            break
        default:
            BrandImage = <Cc />
    }

    return (
        <div key={brand} style={style} className={styles.wrapper} {...props}>
            {BrandImage}
        </div>
    )
}

CardBrandImage.propTypes = {
    brand: PropTypes.oneOf([
        'default',
        'visa',
        'mastercard',
        'amex',
        'discover',
        'diners',
        'jcb'
    ]),
    style: PropTypes.object
}

export default CardBrandImage
