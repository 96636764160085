import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="24">
            <path
                fill="#aaa"
                fillRule="evenodd"
                d="M35 7V5.5A1.5 1.5 0 0033.5 4h-25A1.5 1.5 0 007 5.5V7zm0 5H7v6.5A1.5 1.5 0 008.5 20h25a1.5 1.5 0 001.5-1.5zM8 2h26a3 3 0 013 3v14a3 3 0 01-3 3H8a3 3 0 01-3-3V5a3 3 0 013-3zm1 14h5v2H9zm7 0h10v2H16z"
            ></path>
        </svg>
    )
}

export default Icon
