import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 24">
            <g fill="none" fillRule="evenodd">
                <circle cx="14" cy="12" r="11" fill="#ea001b"></circle>
                <circle cx="28" cy="12" r="11" fill="#f79f1a"></circle>
                <path
                    fill="#ff5f01"
                    d="M21 20.486c2.443-2.02 4-5.07 4-8.486 0-3.416-1.557-6.468-4-8.486-2.443 2.02-4 5.07-4 8.486 0 3.416 1.557 6.468 4 8.486"
                ></path>
            </g>
        </svg>
    )
}

export default Icon
