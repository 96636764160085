import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 24">
            <path
                fill="#1a1f71"
                fillRule="evenodd"
                d="M20.641 5.672l-2.785 13.02h-3.368l2.785-13.02h3.368m14.17 8.408l1.775-4.89 1.019 4.89h-2.794m3.76 4.612h3.116l-2.72-13.02H36.09c-.643 0-1.19.378-1.432.957l-5.05 12.06h3.537l.703-1.944h4.32l.407 1.944m-8.793-4.25c.014-3.436-4.753-3.625-4.718-5.161.01-.467.454-.963 1.427-1.092.484-.062 1.813-.111 3.322.583l.593-2.761c-.81-.293-1.856-.577-3.151-.577-3.329 0-5.674 1.771-5.694 4.304-.02 1.876 1.673 2.919 2.949 3.543 1.313.639 1.754 1.048 1.747 1.619-.008.872-1.045 1.258-2.01 1.274-1.693.026-2.678-.458-3.462-.822l-.609 2.853c.786.362 2.24.675 3.745.691 3.539 0 5.855-1.749 5.865-4.455m-13.951-8.77l-5.457 13.02H6.816L4.132 8.297c-.163-.639-.306-.875-.8-1.142-.81-.441-2.145-.853-3.323-1.11l.08-.378h5.73c.732 0 1.389.486 1.554 1.327l1.419 7.536 3.505-8.863h3.539"
            ></path>
        </svg>
    )
}

export default Icon
